<script>
import Table from "@/components/tables/table.vue";
import DocumentCreate from "@/views/pages/accounts/counterparties/modals/DocumentCreate.vue";
import DocumentDelete from "@/views/pages/accounts/counterparties/modals/DocumentDelete.vue";
import DocumentUpdate from "@/views/pages/accounts/counterparties/modals/DocumentUpdate.vue";
import axios from "axios";

export default {
  name: "DocsByContract",
  data() {
    return {
      docs_request: {
        status: 'success',
        data: {}
      },
      docs_table: {
        name: 'Documents',
        url: `contract/document/${this.$route.params.contract_slug}/`,
        headers: [
          {
            field: 'name',
            label: 'Name',
          },
          {
            field: 'file',
            label: 'File',
            excel_data: (doc) => {
              return doc.file ? doc.file.split('/')[doc.file.split('/').length - 1] : '--'
            }
          },
          {
            field: 'comment',
            label: 'Comment',
          },
          {
            field: 'actions',
            label: 'Actions',
          }
        ]
      },

      contract: {},
      account: {}
    }
  },
  components: {
    Table,
    DocumentCreate,
    DocumentDelete,
    DocumentUpdate
  },
  methods: {
    async getContractDetails() {
      this.contract = 'loading'
      try {
        let response = await axios.get(`/contract/${this.$route.params.contract_slug}/`)
        this.contract = response.data
        this.contract = 'success'
        await this.getContractActs()
      } catch {
        this.contract = 'error'
      }
    },
    async getCompanyAccount() {
      try {
        let response = await axios.get(`/customer/companies/${this.$route.params.slug}/`)
        this.account = response.data
      } catch {
        alert("Customer not found")
        await this.$router.push({name: 'accounts_customers_list'})
      }
    },
    downloadFile(file) {
      if (!file) return alert("Invalid File")
      let url = process.env.VUE_APP_ORDER_URL + file
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
  },
  mounted() {
    this.getCompanyAccount()
    this.getContractDetails()
  },
}
</script>

<template>
  <DocumentCreate ref="documentCreate" @created="this.$refs.docsTable.getData()"/>
  <DocumentDelete ref="documentDelete" @deleted="this.$refs.docsTable.getData()"/>
  <DocumentUpdate ref="documentUpdate" @deleted="this.$refs.docsTable.getData()"/>

  <div v-if="docs_request.status === 'loading'">
    <b-spinner></b-spinner>
  </div>

  <section v-else-if="docs_request.status === 'success'">

    <b-row class="gy-2 mb-3">

      <b-col>
        <b-card class="mb-0">

          <router-link :to="{
            name: `${this.account.is_customer ? 'customer_profile_main': 'counterparty_profile_main'}`
          }"
          >
            <b-button variant="light" class="btn-border">
              <i class="mdi mdi-keyboard-backspace align-bottom me-1"></i>
              Back
            </b-button>
          </router-link>
        </b-card>
      </b-col>

    </b-row>

    <div>
      <Table ref="docsTable" v-bind="docs_table">
        <template v-slot:top-right>
          <b-button @click="this.$refs.documentCreate.openModal()" variant="primary" class="btn-border">
            <i class="mdi mdi-upload align-bottom me-1"></i>
            Upload Document
          </b-button>
        </template>
        <template v-slot:name="{row: data}">
          <h6 class="fw-medium mb-0">{{ data.name }}</h6>
        </template>
        <template v-slot:file="{row: data}">
          <div style="max-width: 600px !important;" class="text-truncate">
            <a @click="downloadFile(data.file)" v-if="data.file"
               class="fw-medium link-secondary cursor-pointer text-truncate mb-0"
            >
              {{ data.file.split('/')[data.file.split('/').length - 1] }}
              <i class="bx bxs-download"></i>
            </a>
            <span v-else>-</span>
          </div>
        </template>
        <template v-slot:comment="{row: data}">
          <h6 class="fw-medium mb-0">{{ data.comment }}</h6>
        </template>
        <template v-slot:actions="{row: data}">
          <b-button-group class="mt-4 mt-md-0" role="group" aria-label="Document Actions">
            <b-button @click="this.$refs.documentUpdate.openModal(data)" variant="light" class="p-0">
              <div class="ps-2 pe-1">
                <i class="ri-pencil-fill align-bottom px-1"></i>
              </div>
            </b-button>
            <b-button @click="this.$refs.documentDelete.openModal(data)" variant="light">
              <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
            </b-button>
          </b-button-group>
        </template>
      </Table>
    </div>

  </section>

  <div v-else-if="docs_request.status === 'error'">
    <b-alert show variant="danger">
      <p class="mb-0">Something went wrong. Please try again later.</p>
    </b-alert>
  </div>
</template>

<style scoped>

</style>