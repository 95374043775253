<template>
  <b-modal v-model="showModal" hide-footer title="Upload Document" class="v-modal-custom" centered>
    <form action="" @submit.prevent="createDoc" class="text-dark" id="CreateDocumentForm">
      <div class="mb-3">
        <label for="docName" class="form-label">
          Document Name
          <span class="text-danger">*</span>
        </label>
        <input v-model="name"
               type="text" class="form-control" id="docName" placeholder="Document name">
      </div>
      <div class="mb-3">
        <label for="docComment" class="form-label">
          Comment
        </label>
        <textarea v-model="comment"
                  class="form-control" id="docComment" rows="3" placeholder="Enter your comment"></textarea>
      </div>
      <div class="mb-3">
        <label for="docFile" class="form-label">
          File
          <span class="text-danger">*</span>
        </label>
        <input id="docFile" type="file" class="form-control" @change="selectedFile">
      </div>
      <div class="mb-3" v-if="file">
        <div class="d-flex p-2">
          <div class="flex-grow-1">
            <div class="pt-1">
              <h5 class="fs-14 mb-1" data-dz-name="">
                {{ file.name }}
              </h5>
              <p class="fs-13 text-muted mb-0" data-dz-size="">
                <strong>{{ file.size / 1024 }}</strong> KB
              </p>
              <strong
                  class="error text-danger"
                  data-dz-errormessage=""
              ></strong>
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <button
                data-dz-remove=""
                class="btn btn-sm btn-danger"
                @click="deleteRecord"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="text-end">
        <button v-if="!isFormValid" type="submit" class="btn btn-success" disabled>Save document</button>
        <button v-if="isFormValid" :type="is_creating ? 'button' : 'submit'" class="btn btn-success">
          {{ is_creating ? 'Creating' : 'Save document' }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {createDocument} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['created'],
  name: "document_create",
  data() {
    return {
      name: '',
      comment: '',
      file: null,
      is_creating: false,
      showModal: false
    }
  },
  computed: {
    isFormValid() {
      return this.name !== '' && this.file !== null
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async createDoc() {
      if (!this.isFormValid) return;
      this.is_creating = true
      let response = await createDocument({
        name: this.name,
        comment: this.comment,
        contract_slug: this.$route.params.contract_slug,
        file: this.file
      })
      this.is_creating = false
      if (response.ok) {
        this.showModal = false
        this.$emit('created')
        await Swal.fire({
          icon: 'success',
          title: 'Document uploaded successfully',
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          html: 'Couldn\'t upload your document. Please, try again later!'
        })
      }
    },
    selectedFile(event) {
      this.file = event.target.files[0];
    },
    deleteRecord() {
      this.file = null
      document.querySelector('#CreateDocumentForm #docFile').value = ''
    },
  },
}
</script>

<style scoped>

</style>