<template>
  <b-modal v-model="showModal" hide-footer title="Delete Document" class="v-modal-custom" centered>
    <h5 class="fw-normal mb-3">Are you sure you want to delete <span class="fw-bolder text-danger me-1">{{
        document.name
      }}</span>?</h5>
    <p>
      <i class="mdi mdi-file text-muted me-1"></i>
      <i>{{ document.file ? document.file.split('/')[document.file.split('/').length - 1] : '--' }}</i>
    </p>
    <form @submit.prevent="deleteDocument()">
      <input v-model="confirmation_text" class="form-control mb-3" placeholder="Yes">
      <div class="text-end">
        <b-button v-if="!is_deleting" variant="danger"  type="submit"
                  :disabled="confirmation_text.trim().toUpperCase() !== 'YES'">
          <i class="bx bx bxs-trash-alt align-middle me-1"></i>
          Yes, Delete
        </b-button>
        <b-spinner v-else variant="danger" class="mb-0"></b-spinner>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {deleteDocumentById} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['deleted'],
  name: "document_edit",
  data() {
    return {
      showModal: false,
      confirmation_text: '',
      document: {},
      is_deleting: false
    }
  },
  methods: {
    openModal(document) {
      this.showModal = true
      this.document = document
    },
    async deleteDocument() {
      if (this.confirmation_text.trim().toUpperCase() !== 'YES') return;
      let response = await deleteDocumentById(this.document.id)
      if (response.ok) {
        this.showModal = false
        this.$emit('deleted')
        await Swal.fire({
          icon: 'success',
          title: 'Document deleted successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
}
</script>

<style scoped>

</style>