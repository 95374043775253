<template>
  <b-modal v-model="showModal" hide-footer title="Delete Document" class="v-modal-custom" centered>
    <form action="" @submit.prevent="editDoc" class="text-dark" v-if="data">
      <div class="mb-3">
        <label for="docName" class="form-label">Document Name</label>
        <input v-model="data.name"
               type="text" class="form-control" id="docName" placeholder="Document name">
      </div>
      <div class="mb-4">
        <label for="docComment" class="form-label">Comment</label>
        <textarea v-model="data.comment"
                  class="form-control" id="docComment" rows="3" placeholder="Enter your comment"></textarea>
      </div>
      <div class="text-end">
        <button v-if="isFormValid" type="submit" class="btn btn-success">Save document</button>
        <button v-else disabled class="btn btn-success">Save document</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {updateDocument} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['update'],
  name: "document_edit",
  data() {
    return {
      doc: {},
      contract_slug: this.$route.params.contract_slug,
      showModal: false
    }
  },
  computed: {
    data() {
      return this.doc
    },
    isFormValid() {
      return this.data.name !== ''
    },
  },
  methods: {
    openModal(doc) {
      this.doc = doc
      this.showModal = true
    },
    async editDoc() {
      let response = await updateDocument({
        id: this.data.id,
        name: this.data.name,
        comment: this.data.comment,
        contract_slug: this.contract_slug,
      })
      if (response.ok) {
        this.showModal = false
        this.$emit('update')
        await Swal.fire({
          icon: 'success',
          title: 'Document updated successfully',
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
  },
}
</script>

<style scoped>

</style>